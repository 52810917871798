import { Button } from "@progress/kendo-react-buttons";
import { RunScriptAsync } from "../../helpers/runscripts";
import { IComboboxItem } from "../../helpers/interfaces";
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
  TextArea,
} from "@progress/kendo-react-inputs";
import BaseComponent from "../BaseComponent";
import FXCard from "../Common/FXCard/FXCard";
import Loader from "../Common/Loader";
import FilterCombobox from "../Common/Form/FilterCombobox";
import ComboboxFilterVirtual from "../Dashboard/ComboboxFilterVirtual";
import formStyles from "./card.module.scss";
import CardManagement from "./CardManagement";
import { IFXCardProps } from "./interfaces";
import { showSomeError } from "../../helpers/helpers";
import React from "react";

interface props extends IFXCardProps {
  buildPlanId?: number;

  afterSave?(): void;
}

interface ITypeItem extends IComboboxItem {
  AllowDispatch: boolean;
  ImportantByDefault: boolean;
}

interface IWOCategory {
  CategoryId: number;
  CategoryName: string;
  IsDefaultCategory: boolean;
}

interface IBPSiteInfo {
  GroupCarrierId?: number;
  BuildPlanSiteId: number;
  IsCarrierGroup: boolean;
}

interface state {
  processing: boolean;
  loading: boolean;
  woCategories: Array<IWOCategory>;
  woTypes: Array<ITypeItem>;
  woSites: Array<IComboboxItem>;
  buildPlans: Array<IComboboxItem>;
  loadingBuildPlans: boolean;
  bpId: number | null;
  selectedWOType: ITypeItem | null;
  selectedWOCategory: IWOCategory | null;
  isImportant: boolean;
}
class AddWOCard extends BaseComponent<props, state> {
  BPID: number | null = this.props.buildPlanId || null;
  WOTID: number | null = null;
  WOCategoryId: number | null = null;
  WONumber: string = "";
  WOSID: number | null = null;
  Description: string = "";
  selectedWOSite: IComboboxItem | null = null;
  IsImportant = false;
  bpSiteInfo: IBPSiteInfo | null = null;

  constructor(props: props) {
    super(props);

    this.state = {
      loading: false,
      processing: false,
      woTypes: [],
      woSites: [],
      woCategories: [],
      buildPlans: [],
      loadingBuildPlans: false,
      bpId: this.props.buildPlanId || null,
      selectedWOType: null,
      selectedWOCategory: null,
      isImportant: false,
    };
  }

  componentDidMount() {
    if (!this.props.buildPlanId) this.LoadBuildPlans();
    else this.LoadActionData();
  }

  render() {
    const {
      bpId,
      processing,
      buildPlans,
      woTypes,
      woSites,
      woCategories,
      loading,
      selectedWOType,
      selectedWOCategory,
      loadingBuildPlans,
    } = this.state;
    const { buildPlanId } = this.props;
    return (
      <FXCard
        title={"New Work Order"}
        onClose={this.props.finally}
        initialWidth={450}
        initialHeight={buildPlanId ? 340 : 370}
        originalPaddings={true}
      >
        <div className={formStyles.FormWrapper}>
          {processing && <Loader />}
          {!buildPlanId && (
            <FilterCombobox
              placeholder="Build Plan *"
              data={buildPlans}
              loading={loadingBuildPlans}
              onChange={this.OnChangeBuildPlan}
              className={formStyles.FormField}
            />
          )}
          <div className={formStyles.Row}>
            <div style={{ flex: 1 }}>
              <FilterCombobox
                placeholder="Type *"
                data={woTypes}
                onChange={this.OnChangeType}
                className={formStyles.FormField}
                loading={loading}
                disabled={!bpId}
                value={selectedWOType}
                required={true}
              />
            </div>
            <div
              style={{
                flex: 1,
                marginBottom: 5,
                paddingLeft: 12,
              }}
            >
              <Checkbox
                label={"Important"}
                size={"large"}
                disabled={!bpId}
                value={this.state.isImportant}
                onChange={(event: CheckboxChangeEvent) => {
                  this.IsImportant = event.value;
                  this.setState({ isImportant: event.value });
                }}
              />
            </div>
          </div>

          <FilterCombobox
            placeholder="Category"
            data={woCategories}
            onChange={this.OnChangeCategory}
            className={formStyles.FormField}
            loading={loading}
            disabled={!bpId}
            value={selectedWOCategory}
            textField={"CategoryName"}
            dataItemKey={"CategoryId"}
          />
          <Input
            placeholder="Number"
            className={formStyles.FormField}
            onChange={this.OnChangeWONumber}
            disabled={!bpId}
          />
          <div className={formStyles.Row}>
            <ComboboxFilterVirtual
              key={"site" + this.selectedWOSite?.Id}
              filter={{
                id: "Site",
                placeholder: "Site",
                type: "combobox",
              }}
              data={woSites}
              onChange={this.OnChangeSite}
              className={`${formStyles.FormField} ${formStyles.AddCombobox}`}
              loading={loading}
              disabled={!bpId}
              defaultValue={this.selectedWOSite}
            />
            <Button
              className={formStyles.AddBtn}
              icon="plus"
              fillMode="flat"
              onClick={this.OpenAddSiteCard}
              title={"Create Site"}
            />
          </div>
          <TextArea
            className={formStyles.TextArea}
            rows={8}
            placeholder="Description"
            onChange={this.OnChangeDescription}
            disabled={!bpId}
          ></TextArea>
          <div className={`${formStyles.FormFooter} k-action-buttons`}>
            <Button onClick={this.props.finally}>Cancel</Button>
            <Button
              onClick={this.AddWOOnly}
              themeColor="primary"
              disabled={!bpId || !selectedWOType}
            >
              Create
            </Button>

            <Button
              onClick={this.AddWOAndSchedule}
              themeColor="primary"
              disabled={!bpId || !selectedWOType?.AllowDispatch}
            >
              Create and Schedule
            </Button>
          </div>
        </div>
      </FXCard>
    );
  }

  OnChangeBuildPlan = (value: IComboboxItem | null) => {
    let valueId = value ? +value.Id : null;
    this.BPID = valueId;
    this.WOSID = null;
    this.WOTID = null;
    this.selectedWOSite = null;
    this.setState({
      bpId: valueId,
      selectedWOType: null,
    });
    if (valueId) this.LoadActionData();
  };

  OnChangeSite = (value: IComboboxItem | null) => {
    this.WOSID = value ? +value.Id : null;
    this.selectedWOSite = value;
  };

  OnChangeType = (value: ITypeItem | null) => {
    this.WOTID = value ? +value.Id : null;
    this.setState({
      selectedWOType: value,
      isImportant: !!value?.ImportantByDefault,
    });
  };

  OnChangeDescription = (e: any) => {
    this.Description = e.value;
  };

  OnChangeWONumber = (e: any) => {
    this.WONumber = e.value;
  };

  OnChangeCategory = (value: IWOCategory | null) => {
    this.WOCategoryId = value?.CategoryId || null;
    this.setState({ selectedWOCategory: value });
  };

  OpenAddSiteCard = () => {
    const { BuildPlanSiteId, GroupCarrierId, IsCarrierGroup } =
      this.bpSiteInfo!;
    CardManagement.OpenSiteCard({
      newSiteDefaultValues: {
        mainSiteId: !IsCarrierGroup ? BuildPlanSiteId : undefined,
        carrierId: IsCarrierGroup ? GroupCarrierId : undefined,
      },
      onFinish: this.LoadActionData,
    });
  };

  LoadBuildPlans = async () => {
    try {
      this.setState({ loadingBuildPlans: true });
      let result: any = await this.GetSQLData({
        spName: "Calendar_AvailableBuildPlans",
      });
      this.setState({ buildPlans: result[0] || [] });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loadingBuildPlans: false });
    }
  };

  LoadActionData = async (siteId?: number /*if after site created*/) => {
    try {
      this.setState({ loading: true });
      const result: any = await this.GetSQLData({
        spName: "Calendar_AddWOActionData",
        params: { bpId: this.BPID },
      });
      const woCategories: IWOCategory[] = result[2] || [];
      const woSites: IComboboxItem[] = result[1] || [];
      this.bpSiteInfo = result[3][0];
      const selectedWOCategory = !siteId
        ? woCategories.find((cat) => cat.IsDefaultCategory) || null
        : this.state.selectedWOCategory;
      this.WOCategoryId = selectedWOCategory?.CategoryId || null;
      if (siteId) {
        const selectedSite = woSites.find((s) => s.Id === siteId);
        if (selectedSite) {
          this.WOSID = +selectedSite.Id;
          this.selectedWOSite = selectedSite;
        }
      }
      this.setState({
        woTypes: result[0] || [],
        woSites,
        woCategories,
        selectedWOCategory,
      });
    } catch (e: any) {
      showSomeError(e);
    } finally {
      this.setState({ loading: false });
    }
  };

  AddWOOnly = () => {
    this.AddWO(false);
  };

  AddWOAndSchedule = () => {
    this.AddWO(true);
  };

  AddWO = async (schedule: boolean) => {
    if (!this.WOTID) return;
    try {
      this.setState({ processing: true });
      const params = {
        BPID: this.BPID,
        WOTID: this.WOTID || null,
        WONumber: this.WONumber,
        WODescription: this.Description,
        WOSID: this.WOSID,
        WOCategoryID: this.WOCategoryId,
        IsImportant: this.IsImportant,
      };
      let result = await RunScriptAsync("Calendar_AddWO", params);
      if (schedule && result) {
        CardManagement.OpenDispatchCard({
          newDispatch: true,
          buildPlanId: this.BPID || undefined,
          woId: +result,
          afterSave: this.props.afterSave,
        });
      }
      this.props.finally?.();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ processing: false });
    }
  };
}

export default AddWOCard;
