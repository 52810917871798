import formStyles from "../Cards/card.module.scss";
import BaseComponent from "../BaseComponent";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-react-buttons";
import FilterCombobox from "../Common/Form/FilterCombobox";
import {
  Checkbox,
  CheckboxChangeEvent,
  Input,
  InputChangeEvent,
  TextArea,
  TextAreaChangeEvent,
} from "@progress/kendo-react-inputs";
import Loader from "../Common/Loader";
import { IComboboxItem } from "../../helpers/interfaces";

import styles from "../Map/ObjectMap/objectmap.module.scss";
import { OpenRecord, RunScriptAsync } from "../../helpers/runscripts";
import { showSomeError, sortByNameCombobox } from "../../helpers/helpers";
import React, { useEffect, useMemo, useState } from "react";

import { canGenerateBP } from "../../core/tools/roles";
import { ReferenceRecordsDataSource } from "../../helpers/queries";
import { IAddressInfo, IEditableSiteInfo } from "./ObjectMap/interfaces";
import ObjectMap from "../Map/ObjectMap/ObjectMap";
import { ISiteCardProps } from "../Cards/interfaces";
import CardManagement from "../Cards/CardManagement";
import ComboboxFilterVirtual from "../Dashboard/ComboboxFilterVirtual";
import { useRefresher } from "../../core/tools/Hooks";
import api from "../../core/api/api";
import {
  PropsSQLTKGetSiteDataResponseSiteData,
  SQL_TK_GetSiteData_Response_RelatedSites,
  SQL_TK_GetSiteData_Response_SiteData,
} from "../../core/api/generated/conterra";

type tabId = "general" | "accessProtocols" | "relatedSites";
const tabs: {
  Id: tabId;
  Text: string;
}[] = [
  { Id: "general", Text: "General" },
  { Id: "accessProtocols", Text: "Access Protocols" },
  { Id: "relatedSites", Text: "Related Sites" },
];

interface state {
  lastResizeCard: number;

  loadedInitialData: boolean;
  contextMenu: null | { left: number; top: number };

  carriers: Array<IComboboxItem>;
  markets: Array<IComboboxItem>;
  types: Array<IComboboxItem>;

  processing: boolean;
  remountKey: number;
  invalidForm: boolean;

  addressString: string;
  activeTab: tabId;

  relatedSites: SQL_TK_GetSiteData_Response_RelatedSites[];
  availableSites: IComboboxItem[] | null;
}

const tabContainerStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  position: "absolute",
  left: 8,
  right: 8,
  top: 0,
  bottom: 0,
  zIndex: -1,
  opacity: 0,
};

const tabContainerActiveStyles: React.CSSProperties = {
  zIndex: 2,
  opacity: 1,
};

class SiteCardView extends BaseComponent<ISiteCardProps, state> {
  isNewSiteCard = this.props.siteId === undefined;
  EditableSiteInfo: IEditableSiteInfo = {
    isCustomName: true,
    siteType: null,
    siteId: "",
    siteName: "",
    market: null,
    region: null,
    responsibleName: "",
    carrier: null,
    address: null,
    directionsFromLocalOffice: "",
    specialAccessInstruction: "",
    notes: "",
    jurisdiction: "",
  };

  RelatedSites: IComboboxItem[] | null = null;
  ServerSiteInfo: SQL_TK_GetSiteData_Response_SiteData | null = null;

  constructor(props: ISiteCardProps) {
    super(props);
    this.state = {
      lastResizeCard: +new Date(),

      loadedInitialData: false,
      contextMenu: null,

      carriers: [],
      markets: [],
      types: [],

      addressString: "",

      processing: false,
      remountKey: +new Date(),
      invalidForm: true,
      activeTab: tabs[0].Id,
      relatedSites: [],
      availableSites: [],
    };
  }

  componentDidMount(): void {
    this.LoadInitialData();
  }

  render() {
    if (this.props.isModalCard) return this.renderContent();

    return (
      <div
        style={{
          padding: 16,
          boxSizing: "border-box",
          height: "100vh",
          background: "#f9f9f9",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {this.renderContent()}
      </div>
    );
  }

  renderContent = () => {
    if (!this.state.loadedInitialData) return <Loader />;
    const { activeTab } = this.state;
    const { carrierId: defaultCarrierId } =
      this.props.newSiteDefaultValues || {};

    return (
      <>
        {this.state.processing && <Loader />}
        <Toolbar style={{ marginBottom: 8, flex: "0 0 auto" }}>
          {tabs.map(({ Id, Text }) => (
            <Button
              key={Id}
              themeColor={activeTab === Id ? "primary" : undefined}
              id={Id}
              onClick={this.ChangeTab}
            >
              {Text}
            </Button>
          ))}
          <ToolbarSpacer />
          {activeTab === "relatedSites" && (
            <Button
              icon={"plus"}
              onClick={() =>
                CardManagement.OpenSiteCard({
                  onFinish: this.LoadAvailableSites,
                })
              }
            >
              New Site
            </Button>
          )}
        </Toolbar>
        <div
          className={formStyles.FormWrapper}
          style={{ position: "relative" }}
        >
          <div
            style={{
              ...tabContainerStyles,
              ...(activeTab === "general" ? tabContainerActiveStyles : {}),
            }}
          >
            <div style={{ display: "flex" }}>
              <div className={styles.Left}>
                <Input
                  key={this.state.remountKey + "siteId"}
                  className={`${formStyles.FormField} ${formStyles.TextField}`}
                  placeholder="Site ID *"
                  defaultValue={this.EditableSiteInfo.siteId}
                  onChange={this.OnChangeTextField}
                  name={PropsSQLTKGetSiteDataResponseSiteData.siteId}
                  required={true}
                />
                <div
                  className={formStyles.Row}
                  style={{
                    height: 30,
                    marginBottom: 4,
                    gap: 8,
                  }}
                >
                  {this.EditableSiteInfo.isCustomName && (
                    <div style={{ flex: 1 }}>
                      <Input
                        key={this.state.remountKey + "siteName"}
                        className={`${formStyles.FormField} ${formStyles.TextField}`}
                        placeholder="Site Name"
                        defaultValue={this.EditableSiteInfo.siteName}
                        name={PropsSQLTKGetSiteDataResponseSiteData.siteName}
                        onChange={this.OnChangeTextField}
                        disabled={!this.EditableSiteInfo.isCustomName}
                        style={{ marginBottom: 0 }}
                      />
                    </div>
                  )}
                  <div className={formStyles.CheckboxRow}>
                    <Checkbox
                      key={this.state.remountKey + "isCustomNameCheckBox"}
                      defaultValue={this.EditableSiteInfo.isCustomName}
                      label={"Use Custom Name"}
                      onChange={this.OnChangeUseCustomName}
                    />
                  </div>
                </div>
                <Input
                  key={this.state.remountKey + "responsible"}
                  className={`${formStyles.FormField} ${formStyles.TextField}`}
                  placeholder="Responsible"
                  defaultValue={this.EditableSiteInfo.responsibleName}
                  onChange={this.OnChangeTextField}
                  name={PropsSQLTKGetSiteDataResponseSiteData.responsibleName}
                />
              </div>
              <div className={styles.Right}>
                <FilterCombobox
                  key={this.state.remountKey + "type"}
                  placeholder="Type"
                  defaultValue={this.EditableSiteInfo.siteType}
                  data={this.state.types}
                  onChange={this.OnChangeField}
                  className={`${formStyles.FormField}`}
                  dataAttr={"siteType"}
                />
                <FilterCombobox
                  key={this.state.remountKey + "market"}
                  placeholder="Market *"
                  defaultValue={this.EditableSiteInfo.market}
                  data={this.state.markets}
                  onChange={this.OnChangeField}
                  className={`${formStyles.FormField}`}
                  dataAttr={"market"}
                  required={true}
                />
                <FilterCombobox
                  key={this.state.remountKey + "carrier"}
                  placeholder="Carrier *"
                  defaultValue={this.EditableSiteInfo.carrier}
                  data={this.state.carriers}
                  onChange={this.OnChangeField}
                  className={`${formStyles.FormField}`}
                  dataAttr={"carrier"}
                  required={true}
                  disabled={
                    !!defaultCarrierId && !!this.EditableSiteInfo.carrier
                  }
                />
                <Input
                  key={this.state.remountKey + "jurisdiction"}
                  className={`${formStyles.FormField} ${formStyles.TextField}`}
                  placeholder="Jurisdiction"
                  defaultValue={this.EditableSiteInfo.jurisdiction}
                  name="jurisdiction"
                  onChange={this.OnChangeTextField}
                />
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <ObjectMap
                lastResize={this.state.lastResizeCard}
                addressId={this.ServerSiteInfo?.addressId}
                coordinates={this.props.coordinates}
                onChange={this.OnAddressChange}
                objectType={"Site"}
              />
            </div>
          </div>

          <div
            style={{
              ...tabContainerStyles,
              ...(activeTab === "accessProtocols"
                ? tabContainerActiveStyles
                : {}),
            }}
          >
            <div style={{ marginBottom: 4 }}>Special Access Instruction:</div>
            <TextArea
              key={this.state.remountKey + "SpecialAccessInstruction"}
              className={`${formStyles.FormField} ${formStyles.TextArea}`}
              style={{ height: 130 }}
              defaultValue={this.EditableSiteInfo.specialAccessInstruction}
              name="specialAccessInstruction"
              onChange={this.OnChangeTextField}
            ></TextArea>
            <div style={{ marginBottom: 4, marginTop: 4 }}>
              Directions from Local Office:
            </div>
            <TextArea
              key={this.state.remountKey + "DirectionsFromLocalOffice"}
              className={`${formStyles.FormField} ${formStyles.TextArea}`}
              style={{ height: 130 }}
              defaultValue={this.EditableSiteInfo.directionsFromLocalOffice}
              name="directionsFromLocalOffice"
              onChange={this.OnChangeTextField}
            ></TextArea>
            <div style={{ marginBottom: 4, marginTop: 4 }}>Notes:</div>
            <TextArea
              key={this.state.remountKey + "Notes"}
              className={`${formStyles.FormField} ${formStyles.TextArea}`}
              style={{ height: 130 }}
              defaultValue={this.EditableSiteInfo.notes}
              name="notes"
              onChange={this.OnChangeTextField}
            ></TextArea>
          </div>

          <div
            style={{
              ...tabContainerStyles,
              ...(activeTab === "relatedSites" ? tabContainerActiveStyles : {}),
            }}
          >
            <RelatedSitesList
              relatedSites={this.state.relatedSites}
              availableSites={this.state.availableSites || []}
              onChange={this.OnChangeRelatedSites}
            />
          </div>
        </div>
        {this.renderFooter()}
      </>
    );
  };

  renderFooter = () => {
    return (
      <div
        className={`${formStyles.FormFooter} k-action-buttons`}
        style={{ paddingLeft: 8, paddingRight: 8 }}
      >
        <span className={formStyles.InvalidMessage}>
          {this.state.invalidForm && <span>Fill all mandatory fields</span>}
        </span>
        {!!this.props.closeModalCard && (
          <Button onClick={this.props.closeModalCard}>Cancel</Button>
        )}
        {canGenerateBP() && (
          <Button
            onClick={
              this.isNewSiteCard ? this.CreateSiteAndGBP : this.SaveSiteAndGBP
            }
            themeColor="primary"
            disabled={this.state.invalidForm}
          >
            {this.isNewSiteCard ? "Create " : "Save "}and Generate BP
          </Button>
        )}
        {this.isNewSiteCard ? (
          <Button
            onClick={this.OnClickCreateSite}
            themeColor="primary"
            disabled={this.state.invalidForm}
          >
            Create Site
          </Button>
        ) : (
          <Button
            onClick={this.OnClickSaveSite}
            themeColor="primary"
            disabled={this.state.invalidForm}
          >
            Save
          </Button>
        )}
      </div>
    );
  };

  ChangeTab = async (e: any) => {
    let tabId = e.currentTarget.id;
    let activeTab = this.state.activeTab;
    if (tabId === "relatedSites" || !this.state.availableSites) {
      await this.LoadAvailableSites();
    }
    if (activeTab !== tabId) this.setState({ activeTab: tabId });
  };

  OnChangeRelatedSites = (
    relatedSites: SQL_TK_GetSiteData_Response_RelatedSites[]
  ) => {
    this.RelatedSites = relatedSites.map(({ id, siteID }) => ({
      Id: id,
      Name: siteID,
    }));
  };

  OnChangeTextField = (e: InputChangeEvent | TextAreaChangeEvent) => {
    let field = e.target.name! as keyof IEditableSiteInfo; // 'SiteName' | 'Notes' | 'SpecialAccessInstruction' |
    // 'DirectionsFromLocalOffice' | 'Jurisdiction'
    this.OnChangeField(e.value, field);
  };

  OnChangeUseCustomName = (e: CheckboxChangeEvent) => {
    this.EditableSiteInfo.isCustomName = e.value;
    this.EditableSiteInfo.siteName = this.EditableSiteInfo.siteId;
    this.setState({ remountKey: +new Date() });
  };

  OnChangeField = (value: any, dataAttr: keyof IEditableSiteInfo) => {
    // @ts-ignore
    this.EditableSiteInfo[dataAttr] = value;
    if (dataAttr === "market") {
      if (value) {
        this.EditableSiteInfo.region = {
          Id: value.HighLvlId,
          Name: value.HighLvlName,
        };
        this.setState({ remountKey: +new Date() });
      }
    } else if (dataAttr === "siteId" && !this.EditableSiteInfo.isCustomName) {
      this.EditableSiteInfo.siteName = value;
    }
    this.setState({ invalidForm: this.IsInValidForm() });
  };

  IsInValidForm = () => {
    if (this.isNewSiteCard) {
      return (
        !this.EditableSiteInfo.siteId ||
        !this.EditableSiteInfo.market ||
        !this.EditableSiteInfo.region ||
        !this.EditableSiteInfo.carrier ||
        !this.EditableSiteInfo.address
      );
    } else {
      return (
        !this.EditableSiteInfo.siteId ||
        !this.EditableSiteInfo.market ||
        !this.EditableSiteInfo.region ||
        !this.EditableSiteInfo.carrier
      );
    }
  };

  LoadAvailableSites = async (siteId?: number) => {
    this.setState({ processing: true });
    try {
      const availableSites: IComboboxItem[] = await ReferenceRecordsDataSource(
        "FSMSites"
      );

      if (siteId) {
        const createdSite = availableSites.find((site) => site.Id === siteId);
        if (createdSite) {
          if (this.RelatedSites === null) this.RelatedSites = [];
          this.RelatedSites.push(createdSite);
        }
      }
      const relatedSites: SQL_TK_GetSiteData_Response_RelatedSites[] = siteId
        ? (this.RelatedSites || []).map((site) => ({
            id: +site.Id,
            siteID: site.Name,
          }))
        : this.state.relatedSites;

      this.setState({
        availableSites,
        relatedSites,
      });
    } catch (e) {
    } finally {
      this.setState({ processing: false });
    }
  };

  LoadInitialData = async () => {
    try {
      const result = !this.isNewSiteCard ? await this.LoadSiteData() : null;
      if (result && result.siteInfo) {
        this.ProcessingSiteData(result);
      }

      const markets = (await ReferenceRecordsDataSource("FSMMarkets", {
        activeOnly: true,
      })) as IComboboxItem[];
      const carriers = (await ReferenceRecordsDataSource("FSMCarriers", {
        activeOnly: true,
      })) as IComboboxItem[];
      const types = (await ReferenceRecordsDataSource("FSMSiteTypes", {
        activeOnly: true,
      })) as IComboboxItem[];
      markets.forEach((item: IComboboxItem) => {
        item.Name = this.GetMarketName(item.HighLvlName!, item.Name);
      });
      markets.sort(sortByNameCombobox);
      const { newSiteDefaultValues } = this.props;
      if (newSiteDefaultValues && newSiteDefaultValues.carrierId) {
        this.EditableSiteInfo.carrier =
          carriers.find((x) => x.Id === newSiteDefaultValues.carrierId) || null;
      }
      this.setState({
        markets,
        carriers,
        types,
        invalidForm: this.IsInValidForm(),
        remountKey: +new Date(),
        relatedSites: result?.relatedSites || [],
      });
    } catch (e) {
    } finally {
      this.setState({ loadedInitialData: true });
    }
  };

  GetMarketName = (regionName: string, marketName: string) =>
    `${regionName}: ${marketName}`;

  ProcessingSiteData = (props: {
    siteInfo: SQL_TK_GetSiteData_Response_SiteData;
    relatedSites: SQL_TK_GetSiteData_Response_RelatedSites[];
  }) => {
    const { siteInfo } = props;
    this.ServerSiteInfo = siteInfo;
    this.EditableSiteInfo.siteId = this.ServerSiteInfo.siteId || "";
    this.EditableSiteInfo.responsibleName =
      this.ServerSiteInfo.responsibleName || "";
    this.EditableSiteInfo.siteName = this.ServerSiteInfo.siteName || "";
    this.EditableSiteInfo.isCustomName = this.ServerSiteInfo.isCustomName;
    if (this.ServerSiteInfo.carrierId && this.ServerSiteInfo.carrierName) {
      this.EditableSiteInfo.carrier = {
        Id: this.ServerSiteInfo.carrierId,
        Name: this.ServerSiteInfo.carrierName,
      };
    }
    if (this.ServerSiteInfo.siteTypeId && this.ServerSiteInfo.siteTypeName) {
      this.EditableSiteInfo.siteType = {
        Id: this.ServerSiteInfo.siteTypeId,
        Name: this.ServerSiteInfo.siteTypeName,
      };
    }
    if (
      this.ServerSiteInfo.marketId &&
      this.ServerSiteInfo.marketName &&
      this.ServerSiteInfo.regionId &&
      this.ServerSiteInfo.regionName
    ) {
      this.EditableSiteInfo.market = {
        Id: this.ServerSiteInfo.marketId,
        Name: this.GetMarketName(
          this.ServerSiteInfo.regionName,
          this.ServerSiteInfo.marketName
        ),
      };
      this.EditableSiteInfo.region = {
        Id: this.ServerSiteInfo.regionId,
        Name: this.ServerSiteInfo.regionName,
      };
    }
    this.EditableSiteInfo.notes = this.ServerSiteInfo.notes || "";
    this.EditableSiteInfo.specialAccessInstruction =
      this.ServerSiteInfo.specialAccessInstruction || "";
    this.EditableSiteInfo.directionsFromLocalOffice =
      this.ServerSiteInfo.directionsFromLocalOffice || "";
    this.EditableSiteInfo.jurisdiction = this.ServerSiteInfo.jurisdiction || "";
  };

  LoadSiteData = async () => {
    const result = await api.sql.tkGetSiteData({ siteId: this.props.siteId });
    const { siteData, relatedSites } = result[0];
    return {
      siteInfo: siteData[0],
      relatedSites,
    };
  };

  OpenSite = () => {
    if (this.props.siteId) OpenRecord("FSMSites", this.props.siteId);
  };

  CreateSiteAndGBP = () => this.CreateSite(true);

  SaveSiteAndGBP = () => this.SaveSite(true);

  OnClickCreateSite = () => this.CreateSite();

  OnClickSaveSite = () => this.SaveSite();

  CreateSite = async (gbp?: boolean) => {
    try {
      this.setState({ processing: true });
      let newSiteId = await RunScriptAsync("FSMSites_Upsert", {
        SiteJSON: this.GetSiteJSON(),
      });
      if (this.props.onFinish) this.props.onFinish(+newSiteId!);
      if (gbp) {
        CardManagement.OpenGBPCard({ siteId: +newSiteId! });
      }
      if (this.props.closeModalCard) this.props.closeModalCard();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ processing: false });
    }
  };

  OnAddressChange = (AddressInfo: IAddressInfo | null) => {
    this.EditableSiteInfo.address = AddressInfo;
    this.setState({
      invalidForm: this.IsInValidForm(),
    });
  };

  GetSiteJSON = () => {
    const {
      isCustomName,
      siteName,
      siteId,
      siteType,
      directionsFromLocalOffice,
      specialAccessInstruction,
      notes,
      carrier,
      region,
      market,
      address,
      jurisdiction,
      responsibleName,
    } = this.EditableSiteInfo;
    return JSON.stringify({
      IsCustomName: isCustomName,
      SiteName: siteName,
      SiteId: siteId,
      DirectionsFromLocalOffice: directionsFromLocalOffice,
      SpecialAccessInstruction: specialAccessInstruction,
      Notes: notes,
      CarrierId: carrier?.Id,
      RegionId: region?.Id,
      MarketId: market?.Id,
      SiteTypeId: siteType?.Id,
      AddressJSON: JSON.stringify(address),
      Jurisdiction: jurisdiction,
      ResponsibleName: responsibleName,
      RelatedSites: this.RelatedSites
        ? this.RelatedSites.map(({ Id }) => Id)
        : undefined,
      MainSiteId: this.props.newSiteDefaultValues?.mainSiteId,
    });
  };

  SaveSite = async (gbp?: boolean) => {
    try {
      this.setState({ processing: true });
      await RunScriptAsync("FSMSites_Upsert", {
        SiteId: this.props.siteId,
        SiteJSON: this.GetSiteJSON(),
      });
      if (this.props.onFinish && this.props.siteId) {
        this.props.onFinish(this.props.siteId);
      }
      if (gbp) {
        CardManagement.OpenGBPCard({ siteId: this.props.siteId });
      }
      if (this.props.closeModalCard) this.props.closeModalCard();
    } catch (e) {
      showSomeError(e);
    } finally {
      this.setState({ processing: false });
    }
  };
}

function RelatedSitesList(props: {
  availableSites: IComboboxItem[];
  relatedSites: SQL_TK_GetSiteData_Response_RelatedSites[];
  onChange: (relatedSites: SQL_TK_GetSiteData_Response_RelatedSites[]) => void;
}) {
  const [relatedSites, setRelatedSites] = useState<
    (SQL_TK_GetSiteData_Response_RelatedSites | null)[]
  >([]);
  const comboBoxRefresher = useRefresher();

  useEffect(() => {
    setRelatedSites([...props.relatedSites]);
  }, [props.relatedSites]);

  useEffect(() => {
    props.onChange(
      relatedSites.filter(
        (rs) => !!rs
      ) as SQL_TK_GetSiteData_Response_RelatedSites[]
    );
  }, [relatedSites, props.onChange]);

  const addRow = () => {
    setRelatedSites([...relatedSites, null]);
  };

  const deleteRow = (index: number) => {
    relatedSites.splice(index, 1);
    setRelatedSites([...relatedSites]);
  };

  const onChange = (index: number, value: IComboboxItem | null) => {
    relatedSites[index] = value
      ? {
          id: +value.Id,
          siteID: value.Name,
        }
      : null;
    setRelatedSites([...relatedSites]);
  };

  const availableSites = useMemo(() => {
    return props.availableSites.filter(
      (site) => !relatedSites.some((rs) => rs?.id === site.Id)
    );
  }, [props.availableSites, relatedSites]);

  useEffect(() => {
    comboBoxRefresher();
  }, [availableSites, comboBoxRefresher]);

  if (availableSites.length === 0) return null;
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ overflow: "auto" }}>
        {relatedSites.map((item, i) => {
          return (
            <div
              className={formStyles.Row}
              key={i.toString() + comboBoxRefresher.value}
            >
              <div
                style={{
                  flex: 1,
                  paddingRight: 8,
                  boxSizing: "border-box",
                }}
              >
                <ComboboxFilterVirtual
                  width={400}
                  placeholder="Site"
                  data={availableSites}
                  defaultValue={
                    item
                      ? {
                          Id: item.id,
                          Name: item.siteID,
                        }
                      : undefined
                  }
                  onChange={(value) => onChange(i, value)}
                  className={formStyles.FormField}
                  filter={i}
                />
              </div>
              <div
                style={{
                  width: 32,
                  textAlign: "right",
                }}
              >
                <Button
                  icon="minus"
                  fillMode="flat"
                  title="Delete"
                  style={{ marginBottom: 5 }}
                  onClick={() => deleteRow(i)}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className={formStyles.Row} key={"newRow"}>
        <Button
          icon="plus"
          onClick={addRow}
          disabled={relatedSites.some((item) => item === null)}
          style={{
            width: "100%",
            padding: 0,
            height: "auto",
            border: "none",
          }}
        />
      </div>
    </div>
  );
}

export default SiteCardView;
