import {
  ColDef,
  EditableCallbackParams,
  ValueFormatterParams,
} from "ag-grid-enterprise";
import styles from "./aggrid.module.scss";
import { formatFinancial } from "../../helpers/helpers";
import moment from "moment";
import { CustomCellEditorProps, CustomCellRendererProps } from "ag-grid-react";
import { TextArea } from "@progress/kendo-react-inputs";

export enum COMMON_COL_TYPES {
  currency = "currency",
  booleanYesNo = "booleanYesNo",
  dateFormatted = "dateFormatted",
  dateTimeFormatted = "dateTimeFormatted",
  float = "float",
  integer = "integer",
  percentage = "percentage",
  longReadonlyText = "longReadonlyText",
}
const yesNoLib = {
  true: "Yes",
  false: "No",
};
export const COMMON_COL_TYPES_DESCRIPTION = {
  [COMMON_COL_TYPES.percentage]: {
    initialWidth: 110,
    minWidth: 90,
    filter: "agNumberColumnFilter",
    cellClass: styles.CenterCellContent,
    valueFormatter: (p: ValueFormatterParams) => {
      if (isNaN(p.value) || p.value === null) return "";
      return p.value.toFixed(2) + "%";
    },
  },
  [COMMON_COL_TYPES.float]: {
    initialWidth: 100,
    minWidth: 70,
    filter: "agNumberColumnFilter",
    aggFunc: "sum",
    cellClass: styles.CenterCellContent,
    valueFormatter: (p: ValueFormatterParams) => {
      if (isNaN(p.value) || p.value === null) return "";
      return p.value.toFixed(2);
    },
  },
  [COMMON_COL_TYPES.integer]: {
    initialWidth: 100,
    minWidth: 70,
    filter: "agNumberColumnFilter",
    aggFunc: "sum",
    cellClass: styles.CenterCellContent,
  },
  [COMMON_COL_TYPES.currency]: {
    initialWidth: 150,
    minWidth: 100,
    filter: "agNumberColumnFilter",
    aggFunc: "sum",
    valueFormatter: (p: ValueFormatterParams) => {
      if (isNaN(p.value) || p.value === null) return "";
      return `${formatFinancial(p.value)}`;
    },
    cellRenderer: (p: CustomCellRendererProps) => {
      if (!p.valueFormatted) return "";
      return (
        <div className={styles.CurrencyCellContent}>
          <span>$</span>
          <span>{p.valueFormatted}</span>
        </div>
      );
    },
    enableValue: true,
  },
  [COMMON_COL_TYPES.dateFormatted]: {
    enableValue: true,
    initialWidth: 140,
    minWidth: 90,
    cellClass: styles.CenterCellContent,
    valueFormatter: (p: ValueFormatterParams) => {
      if (!p.value) return "";
      return moment(p.value).format("L");
    },
  },
  [COMMON_COL_TYPES.dateTimeFormatted]: {
    enableValue: true,
    initialWidth: 150,
    minWidth: 100,
    cellClass: styles.CenterCellContent,
    valueFormatter: (p: ValueFormatterParams) => {
      if (!p.value) return "";
      return moment(p.value).format("L LT");
    },
  },
  [COMMON_COL_TYPES.booleanYesNo]: {
    initialWidth: 100,
    minWidth: 80,
    cellClass: styles.CenterCellContent,
    refData: yesNoLib,
    cellRenderer: (p: any) => {
      if (!p.valueFormatted) return "";

      return (
        <span
          className={p.value ? "mdi mdi-check" : "mdi mdi-minus"}
          style={{
            color: p.value ? "#0072c6" : "grey",
            opacity: p.value ? 0.8 : 0.7,
            fontSize: 18,
          }}
        ></span>
      );
    },
  },
  [COMMON_COL_TYPES.longReadonlyText]: {
    cellClass: "comment-cell",
    cellEditorPopup: true,
    cellEditor: (params: CustomCellEditorProps) => (
      <TextArea
        defaultValue={params.value}
        readOnly={true}
        rows={10}
        style={{ width: 400, padding: 8 }}
      ></TextArea>
    ),
    editable: (params: EditableCallbackParams) => {
      const {
        colDef: { field },
        data,
      } = params;
      return !!(field && data[field]);
    },
  },
} as Record<COMMON_COL_TYPES, ColDef>;
