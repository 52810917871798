import {Input, InputChangeEvent} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import Logo from "../../assets/img/Fieldclix-Logo.svg";
import React, {useCallback, useRef, useState} from "react";
import {ILastLogin, ILoginCredentials} from "../../core/interfaces";
import styles from "./authform.module.scss";
import {authProvider} from "../../core/api/api";
import {getParamsFromUrl, groupBy, showSomeError} from "../../helpers/helpers";
import {useBooleanState} from "../../core/tools/Hooks";
import LastLoginItem from "./LastLoginItem";
import {Card, CardBody} from "@progress/kendo-react-layout";
import LoaderComponent from "../../Components/Common/Loader";
import {PasswordField} from "./PasswordField";

const Auth = () => {
  const [isValidForm, setIsValidForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isProcessing = useBooleanState(false);
  const lastLogins = authProvider.getLastLogins();
  const {showForm, user, instance} = getParamsFromUrl();
  const showLastLogins = useBooleanState(!showForm && !!lastLogins.length);
  const defaultLastLogin =
    !!showForm &&
    !!user &&
    !!instance &&
    !!lastLogins.length &&
    lastLogins.find((l) => l.UserId === user && l.InstanceId === instance);
  const valuesRef = useRef<ILoginCredentials>({
    instanceId: (defaultLastLogin || {}).InstanceId || "",
    login: (defaultLastLogin || {}).login || "",
    password: "",
  });
  if (showForm) {
    window.history.pushState({}, document.title, window.location.pathname);
  }
  const onSelectLastLogin = useCallback((lastLogin: ILastLogin) => {
    if (lastLogin.isAuthorized) {
      authProvider.tryUseLogin(lastLogin);
      return;
    }
    valuesRef.current = {
      instanceId: lastLogin?.InstanceId || "",
      login: lastLogin?.login || "",
      password: "",
    };
    showLastLogins.setFalse();
  }, []);

  const onChange = (e: InputChangeEvent) => {
    const value = e.target.value ? e.target.value.toString() : "";
    if (e.target.name === "sInstanceId") {
      valuesRef.current;
      valuesRef.current.instanceId = value;
    } else if (e.target.name === "sLogin") {
      valuesRef.current.login = value;
    } else {
      valuesRef.current.password = value;
    }
    const {login, password, instanceId} = valuesRef.current;
    setIsValidForm(!!login && !!password && !!instanceId);
  };

  const auth = async () => {
    try {
      isProcessing.setTrue();
      const {login, password, instanceId} = valuesRef.current;
      const error = await authProvider.login({
        login,
        password,
        instanceId,
      });
      if (error) setErrorMessage(error);
    } catch (error: any) {
      showSomeError(error);
    } finally {
      isProcessing.setFalse();
    }
  };

  if (isProcessing.value) return <LoaderComponent/>;

  if (showLastLogins.value) {
    const data = groupBy(lastLogins, (x) => x.InstanceId);

    return (
      <div className={styles.AuthContainer}>
        <div className={styles.AuthForm}>
          <div style={{fontSize: 16, textAlign: "center"}}>Last Logins</div>
          <div
            className={`${styles.AuthFormList} ${Object.keys(data).length > 10 ? styles.AuthFormListCompact : ""}`}
          >
              {Object.keys(data)
                  .sort((a, b) => a.localeCompare(b))
                  .map((instanceId) => {
              const users = data[instanceId];
              return (
                <Card
                  key={instanceId}
                  style={{
                    width: "100%",
                    overflow: "visible",
                  }}
                >
                  <CardBody style={{padding: 4}}>
                    {/*<CardTitle style={{
                                    position: 'absolute',
                                    left: 10,
                                    top: -5,
                                    background: '#fff'
                                }}>{instanceId}</CardTitle>*/}
                    {users
                      .sort((a, b) => a.login.localeCompare(b.login))
                      .map((user) => (
                        <LastLoginItem
                          key={user.UserId}
                          l={user}
                          onSelect={onSelectLastLogin}
                        />
                      ))}
                  </CardBody>
                </Card>
              );
            })}
          </div>
          <Button
            icon={"plus"}
            fillMode={"flat"}
            style={{width: "100%"}}
            onClick={showLastLogins.setFalse}
            themeColor={"primary"}
          >
            Use Different User
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.AuthContainer}>
      <div className={styles.AuthForm}>
        <img className={styles.AuthLogo} src={Logo} alt=""/>
        <Input
          style={{width: "100%", marginTop: "5px"}}
          id="sInstanceId"
          autoComplete="sInstanceId"
          name="sInstanceId"
          label="Company Code"
          defaultValue={valuesRef.current.instanceId}
          onChange={onChange}
        />
        <Input
          style={{width: "100%", marginTop: "5px"}}
          id="sLogin"
          autoComplete="sLogin"
          name="sLogin"
          label="Login"
          defaultValue={valuesRef.current.login}
          onChange={onChange}
        />
        <PasswordField
          styles={{width: "100%", marginTop: "5px"}}
          value={valuesRef.current.password}
          onChange={onChange}
          onEnter={auth}
        />
        <div className={styles.AuthMessage}>{errorMessage}</div>
        <Button
          onClick={auth}
          className={styles.AuthSubmit}
          disabled={!isValidForm}
        >
          Sign In
        </Button>
      </div>
    </div>
  );
};

export default Auth;
